<script lang="ts">
    import { onMount } from 'svelte';
    import { postsStore, isLoading, currentPosts } from '@/stores/pagination';
    import { loadMorePosts } from '@/utils/posts-loader';
    import { infiniteScroll } from '@/actions/infinite-scroll';
    import SearchBox from '@/Components/SearchBox.svelte';
    import SuggestionsBox from '@/Components/SuggestionsBox.svelte';
    import ExploreTab from '@/Components/explore/exploreTab.svelte';
    import PostsWrapper from '@/elements/Feed/PostsWrapper.svelte';
    import type { Data, User } from '@/interfaces/types';
    import { subscribeToPushNotifications } from '@/utility/pushNotifications';

    export let isAppleDevice: boolean = false;
    export let vapidPublicKey;
    export let suggestions: any[] = [];
    export let initialPosts: Data;
    export let paginatorConfig: {
        next_page_url: string | null;
        hasMore: boolean;
    };
    export let data;
    export let authUser: User;
    export let reportStatuses: string[];

    export let paymentSettings = {
        stripeSecretKey: data.stripeSecretKey,
        stripePublicKey: data.stripePublicKey,
        isStripeCheckoutDisabled: data.isStripeCheckoutDisabled,
        paypalClientId: data.paypalClientId,
        paypalSecret: data.paypalSecret,
        isPaypalCheckoutDisabled: data.isPaypalCheckoutDisabled,
        coinbaseApiKey: data.coinbaseApiKey,
        isCoinbaseCheckoutDisabled: data.isCoinbaseCheckoutDisabled,
        nowPaymentApiKey: data.nowPaymentApiKey,
        isNowPaymentsCheckoutDisabled: data.isNowPaymentsCheckoutDisabled,
        isCCbillCredentialsProvided: data.isCCbillCredentialsProvided,
        paystackSecretKey: data.paystackSecretKey,
        isPaystackCheckoutDisabled: data.isPaystackCheckoutDisabled,
        isStripeOXXOProviderEnabled: data.isStripeOXXOProviderEnabled,
    };

    let serviceWorkerRegistration: ServiceWorkerRegistration | null = null;
    let subscriptionStatus = 'Not subscribed';
    let error = '';

    onMount(async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/service-worker.js');

                if (!registration.active) {
                    await new Promise((resolve) => {
                        registration.addEventListener('updatefound', () => {
                            const newWorker = registration.installing;
                            newWorker.addEventListener('statechange', () => {
                                if (newWorker.state === 'activated') resolve();
                            });
                        });
                    });
                }
                serviceWorkerRegistration = registration;

                const existingSubscription = await registration.pushManager.getSubscription();
                if (existingSubscription) {
                    subscriptionStatus = 'Already subscribed';
                } else {
                    const { error: subscrbeError, subscriptionStatus: subscribeStatus } =
                        await subscribeToPushNotifications(serviceWorkerRegistration, vapidPublicKey);
                    error = subscrbeError;
                    subscriptionStatus = subscriptionStatus;
                }
            } catch (err) {
                console.error('Service Worker registration failed:', err);
                error = 'Failed to register service worker';
            }
        } else {
            error = 'Push notifications not supported';
        }

        postsStore.initialize(initialPosts, paginatorConfig.next_page_url, paginatorConfig.hasMore);
        return () => {
            postsStore.reset();
        };
    });

    function handlePostAction(event: CustomEvent<{ action: string; postId: number }>) {
        const { action, postId } = event.detail;
        // TODO: Implement post action handling logic
    }
</script>

<svelte:head>
    <title>Your Feed</title>
</svelte:head>

<div class="container">
    <div class="row">
        <div class="w-full sm:block md:hidden">
            <ExploreTab {authUser} />
        </div>
        <div class="col-12 col-sm-12 col-lg-8 col-md-7 second p-0">
            <div class="d-md-none feed-mobile-search hidden px-3 py-3 md:flex">
                <SearchBox />
            </div>
            <div class="d-md-none d-lg-none hidden md:block">
                <SuggestionsBox {suggestions} />
            </div>

            <div class="feed-container" use:infiniteScroll="{{ callback: loadMorePosts }}">
                <div class="feed-box posts-wrapper mt-0 pt-4">
                    <PostsWrapper
                        data="{$currentPosts}"
                        {paymentSettings}
                        {authUser}
                        {reportStatuses}
                        on:postAction="{handlePostAction}"
                    />
                </div>

                {#if $isLoading}
                    <div class="loading-spinner">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                {/if}
            </div>
        </div>

        <div
            class="col-12 col-sm-12 col-md-5 col-lg-4 first border-left order-0 min-vh-100 d-none d-md-block pb-5 pt-4 dark:!border-neutral-800"
        >
            <div class="feed-widgets">
                <div class="mb-4">
                    <SearchBox />
                </div>

                <div class="suggestions-box">
                    <SuggestionsBox {suggestions} />
                </div>

                <div class="ad-space mt-4">
                    <!-- Ad content -->
                </div>
            </div>
        </div>
    </div>

    <div class="checkout-modal"></div>
    <div class="dialog" style="display: none;">
        <div class="dialog-content">
            <h3>Delete Comment</h3>
            <p>Are you sure you want to delete this comment?</p>
            <div class="dialog-actions">
                <button class="btn btn-secondary">Cancel</button>
                <button class="btn btn-danger">Delete</button>
            </div>
        </div>
    </div>
</div>

<style>
    .loading-spinner {
        display: flex;
        justify-content: center;
        padding: 2rem;
    }
</style>
